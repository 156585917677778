<template>
    <workspace-wrapper>
        <template #workspace>
            <hotline-workspace :selectedSymbol="stateData.currentSpinResult.selectedSymbol"
                :symbols="stateData.currentSpinResult.history.symbols" />
        </template>
    </workspace-wrapper>
</template>

<script>
import WorkspaceWrapper from "../../Common/WorkspaceWrapper";
import HotlineWorkspace from "./HotlineWorkspace.vue";

export default {
    props: {
        stateData: {
            type: Object,
            required: true,
            default() {
                return {
                    currentSpinResult: {
                        history: {
                            symbols: null
                        },
                        selectedSymbol: null,
                    }
                }
            }
        }
    },
    components: {
        WorkspaceWrapper,
        HotlineWorkspace
    }
}
</script>

<style scoped></style>
